import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SidebarComponent } from './views/common/sidebar/sidebar.component';
import { FooterComponent } from './views/common/footer/footer.component';
import { HeaderComponent } from './views/common/header/header.component';
import { SidebarRightComponent } from './views/common/sidebar-right/sidebar-right.component';
import { RelaysComponent } from './views/relays/relays.component';
import { SensorComponent } from './views/common/sensor/sensor.component';
import { RelayComponent } from './views/common/relay/relay.component';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { HistoryComponent } from './views/history/history.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    SidebarComponent,
    FooterComponent,
    HeaderComponent,
    SidebarRightComponent,
    RelaysComponent,
    SensorComponent,
    RelayComponent,
    HistoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSlideToggleModule,
    AngularFireModule.initializeApp(environment.firebase, 'tcr'),
    AngularFireDatabaseModule
  ],
  providers: [AngularFireAuth],
  bootstrap: [AppComponent]
})
export class AppModule { }

