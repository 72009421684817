import { Component, OnInit, Directive, Input } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-relay',
  templateUrl: './relay.component.html',
  styleUrls: ['./relay.component.scss'],
  inputs: ['device', 'index']
})


export class RelayComponent {

  @Input() relay: any;

  constructor(private _db: FirebaseService) {
  }

  setRelay(d: string, r: string, v: boolean) {
    this._db.setRelay(d, r, v);
  }



}
