import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-sensor',
  templateUrl: './sensor.component.html',
  styleUrls: ['./sensor.component.scss'],
  inputs: ['sensor', 'device'],
})
export class SensorComponent {

  @Input() index: number;
  @Input() sensor: any;
  @Input() device: any;

  constructor(public _db: FirebaseService) {
  }
  color = ['red', 'green', 'blue', 'yellow'];
}
