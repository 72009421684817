import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(public _auth: AuthService, private _router: Router, public _db: FirebaseService) {
    //this._db.getDevices().subscribe(o => { this.devList = o; });
  }

  //devList: any;

  ngOnInit() {
  }

  doLogin() { this._auth.doLogin(); }
  doLogout() { this._auth.doLogout(); }
  isAuth() { return this._auth.authenticated; }



}
