import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _auth: AuthService, private _router: Router) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    // Observable<boolean> | Promise<boolean> | boolean {
      //console.log('auth.authenticated', this._auth.authenticated);
      if (await this._auth.authenticated) {
        return true;
      } else {
        this._router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
      }

  }
}
