import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Title } from '@angular/platform-browser';
import { FirebaseService } from './services/firebase.service';
import { User } from './models';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  version: string = 'unkown';

  constructor(public _auth: AuthService, private _db: FirebaseService, private route: ActivatedRoute) {
    this.version = environment.version;
  }
  title = 'TCR';

}
