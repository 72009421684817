import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Relay, Sensor, Device } from './../models';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {



  //devicesRef: AngularFireList<Device>;
  //devices: Device[];



  devices: any;
  //devicesRef: any;
  //currentDevice: any;

  /*

  device: any;
  relay: any;
  sensor: any;
  id: string;

  relaysRef: Observable<any>;
  sensorsRef: Observable<any>;
  devicesRef: Observable<any>;

  sensorsHistoryRef: Observable<any>;
  
  relays: any;
  sensors: any;
  sensorsHistory: ['1', '2', '44'];// any;
  */
  constructor(private db: AngularFireDatabase) {

    this.db.list(`/devices/`).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    )
    .subscribe(o => { this.devices = o; });
  }





  public setRelay(d: string, r: string, v: boolean) {
    if (d) {
      return this.db.object(`/devices/${d}/relays/${r}`).set(v).then(
        () => console.log(`Relay ${r} on ${d} set ${v}`)
      ).catch(r => console.log('catch', r));
    }
  }

  //setBidirectionalRelay
  public setBidirectionalRelay(d: string, r: string, v: boolean) {
    if (d) {
      return this.db.object(`/devices/${d}/relays/${r}/value/request`).set(v).then(
        () => console.log(`Relay ${r} on ${d} set ${v}`)
      ).catch(r => console.log('catch', r));
    }
  }


  /*

    public getDevices() {
    let devices;
    this.db.list(`/devices/`).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(o => { devices = o; });
    return devices;
  }

    public getDeviceRelays(d: string) {
    return this.db.list(`/devices/${d}/relays`).snapshotChanges()

  }

  public getDeviceSensors(d: string) {
    return this.db.list(`/devices/${d}/sensors`).snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    );
  }
  public ifDeviceExist(d: string) {
    
    return this.db.list(`/devices/${d}`).valueChanges().subscribe(o => {
      if (o.length > 0) {
        console.log('ifDeviceExist', d, true, o);
        return true;
      } else {
        console.log('ifDeviceExist', d, false, o);
        return false;
      }
    });
  }
  

  public getRelays(d: string) {
    return this.db.list(`/devices/${d}/relays`).valueChanges();
  }

  public getSensors(d: string) {
    return this.db.list(`/devices/${d}/sensors`).valueChanges();
  }

  public getDevices() {
    return this.db.list(`/devices/`).valueChanges();
  }

  public getDevice(d: string) {
    return this.db.list(`/devices/${d}/`).valueChanges();
  }
*/
}
