import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { NG_ASYNC_VALIDATORS } from '@angular/forms';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-relays',
  templateUrl: './relays.component.html',
  styleUrls: ['./relays.component.scss']
})
export class RelaysComponent {

  constructor(_auth: AuthService, public _db: FirebaseService, private route: ActivatedRoute, private router: Router) {

  }



}

