import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  returnUrl: string;

  constructor(public _auth: AuthService, private _router: Router, private _route: ActivatedRoute, public cdRef: ChangeDetectorRef) { }

  ngOnInit() {

  }

  doLogin() {
    if (!this._auth.authenticated) {
      this._auth.doLogin();
    }
    this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/';
    this._router.navigateByUrl(this.returnUrl);

    //this._router.navigate(['/']);
  }



}
