import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //authState: any = null;

  private _user: firebase.User = null;

  constructor(private _router: Router, private _afAuth: AngularFireAuth) {
    this._afAuth.authState.subscribe((user) => {
      this.user = user;
    })
  }

  get user(): firebase.User {
    return this._user;
  }

  set user(value: firebase.User) {
    this._user = value;
  }

  doLogin(): void {
    //this.doLogout();
    this._afAuth.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(() => {
      console.log('Zalogowano.');
      this._router.navigate(['']);
    });

  }

  doLogout(): void {
    this._afAuth.auth.signOut().then(() => {
      console.log('Wylogowano.');
      this._router.navigate(['login']);
    });
  }

  get authenticated(): boolean {
    console.log('user', this._user);
    return this._user !== null;
  }
}
