import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  chart = [];
  chartDates = ['1', '2', '22']

  constructor(_auth: AuthService, public _db: FirebaseService) { }

  ngOnInit() {

    /*
      this.chart = new Chart('canvas', {
        type: 'line',
        data: {
          labels: this.chartDates,
          datasets: [
            { 
              data: this._db.sensorsHistory,
              borderColor: "#3cba9f",
              fill: false
            },
            { 
              data: this._db.sensorsHistory,
              borderColor: "#ffcc00",
              fill: false
            },
          ]
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              display: true
            }],
            yAxes: [{
              display: true
            }],
          }
        }
      }); */
  }
}
